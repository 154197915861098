.aboutBanner img{
  width: 100%;
}

.aboutBanner p{
  font-size: 40px;
  margin: 20px 50px;
  font-weight: bolder;
  text-align: center;
  color: var(--customDarkBlue);
  text-shadow: 2px 2px 4px rgb(116, 192, 197);
}

.aboutMoto{
  display: flex;
  gap: 30px;
  margin: 50px;
  color: gray;
  align-items: center;
}

.aboutMoto p{
  text-align: justify;
}

.aboutMoto .moto span{
  display: inline-block;
  width: 60px;
  border: 1px solid rgb(255,102,59);
  margin-right: 10px;
}

.aboutMoto .moto h1{
  color: var(--customDarkBlue);
  font-weight: bolder;
  margin: 30px 0;
}

.aboutMoto .motoImg{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aboutMoto .motoImg img{
  width: 100%;
  max-width: 500px;
}

.whoWeAre{
  margin: 0 50px;
}
.whoWeAre .title{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  color: var(--customDarkBlue);
}

.whoWeAre .title h1{
  font-weight: bolder;
  color: var(--customDarkBlue);
  margin: 20px 0;
}
.whoWeAre .title .spanDiv{
  display: inline-block;
  width: 100px;
  border: 1px solid rgb(255,102,59);
}

.whoWeAre .info{
  text-align: justify;
  color: gray;
  margin-bottom: 50px;
}


@media (max-width:1100px){
  .aboutBanner p{
    font-size: 30px;
  }
}

@media (max-width:950px){
  .aboutMoto{
    flex-direction: column;
  }
  .aboutMoto .motoImg img{
    max-width: 400px;
  }
  .aboutBanner p{
    font-size: 25px;
  }
  .aboutMoto{
    margin: 50px 20px;
  }
  .aboutBanner p{
    margin: 20px;
  }
  .whoWeAre{
    margin: 0 20px;
  }

}


@media (max-width: 425px){
  .aboutMoto .motoImg img{
    max-width: 300px;
  }
  .aboutBanner p{
    font-size: 20px;
  }
}