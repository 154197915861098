.homeDiv{
  height: calc(100vh - 80px);
  background-image: url("./banner_bg.png");
  background-repeat: no-repeat;
  background-size: 68%;
  background-position: right top;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
}

.frameContent h5{
  color: gray;
  text-align: justify;
}

.frameContent button{
  border-radius: 25px;
  width: 30%;
  height: 50px;
  background-color: white;
  outline-color: rgb(27,214,252);
  /* font-size: 20px; */
}

.frameContent h1{
  color: var(--customDarkBlue);
  font-size: 50px;
  font-weight: bolder;
}

.frameImg img{
  width: 600px;
  animation: homeGifAnimation 4s infinite;
}

@keyframes homeGifAnimation {
  50%{
    transform: translateY(-20px);
  }
  0%, 100%{
    transform: translateY(20px);
  }
}

.whyUs{
  text-align: center;
  font-size: 18px;
}

.whyUs h1{
  font-size: 40px;
  color: var(--customDarkBlue);
  font-weight: bold;
}

.whyUs h5{
  color: gray;
  margin: 0 20px;
}

.whyUsContain{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
}

.whyUs .singleContain{
  width: 300px;
  min-width: 300px;
  padding: 20px;
  transition: all 0.6s ease 0s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--customDarkBlue);
  cursor: pointer;
  margin: 15px;
}
.whyUs .singleContain .logoDiv{
  margin-bottom: 30px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(240, 244, 246);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  transition: all 0.6s ease 0s;
}

.whyUs .singleContain:hover{
  box-shadow:rgb(255,102,59)  0px 1px 4px;
  transform: translateY(-10px);
}

.whyUs .singleContain:hover .logoDiv{
  color: white;
  background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important
}

.whyUs .singleContain p{
  color: gray;
  text-align: center;
  margin-top: 20px;
}

.advanceFeature{
  display: flex;
  margin: 50px;
  gap: 50px;
}

.advanceFeature .heading h5{
  color: gray;
}

.advanceFeature .heading .headSpan{
  display: inline-block;
  width: 60px;
  border: 1px solid rgb(255,102,59);
  margin-right: 10px;
}

.advanceFeature .heading h1{
  color: var(--customDarkBlue);
  font-weight: bolder;
  margin: 30px 0;
}

.advanceFeature .heading p{
  color: gray;
  text-align: justify;
}

.advanceFeature .ImgDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  /* background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important; */
  border-top-left-radius:40% ;
  border-top-right-radius: 60%;
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 40%;

}

.advanceFeature .ImgDiv img{
  width: 500%;
  border-radius: 25px;
  
}

.advanceFeature .Points{
  display: flex;
  justify-content: center;
  gap: 30px;
}

.advanceFeature .Points .subPoint{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
}

.advanceFeature .Points .subPoint .logo{
  margin-bottom: 30px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  color: white;
  /* background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}
.advanceFeature .Points .subPoint .logo img{
  margin-top: 60px;
  width: 200%;
  height: 150px;
  border-radius: 50%;
}


.advanceFeature .Points .subPoint h3{
  color: var(--customDarkBlue);
  margin-bottom: 20px;
}

.advanceFeature .Points .subPoint p{
  color: gray;
  text-align: center;
}

.testimonial{
  margin: 50px;
}

.testimonial .heading{
  display: flex;
  align-items: center;
  flex-direction: column;
  color: gray;
  margin-bottom: 50px;
}

.testimonial .heading h1{
  font-weight: bolder;
  color: var(--customDarkBlue);
  margin: 20px 0;
}

.testimonial .heading .spanDiv{
  display: inline-block;
  width: 100px;
  border: 1px solid rgb(255,102,59);
}

.testimonial .students{
  display: flex;
  gap: 50px;
  overflow-x: scroll;
  padding: 10px;
}

.testimonial .students::-webkit-scrollbar{
  display: none;
}

.testimonial .individualStudent{
  width: 300px;
  height: 350px;
  min-width: 300px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  text-align: justify;
  border-radius: 10px;
  position: relative;
}

.testimonial .individualStudent p{
  color: gray;
}

.testimonial .individualStudent h5{
  color: var(--customDarkBlue);
}

.testimonial .nameAndPosition{
  position: absolute;
  bottom: 10px;
  left: 20px
}

.testimonial .nameAndPosition .fa-star{
  margin-right: 5px;
  color: gray;
}

.testimonial .nameAndPosition .fa-star.like{
  color: rgb(242,98,11);
}


/* --------------how to apply---------- */
.howToApply{
  /* background-color: aquamarine; */
  margin: 0 50px 50px;
}

.processContainer{
  /* background-color: aquamarine; */
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0,0,0, 0.1);
  counter-reset: processCount;

}

.howToApply h1, .InternshipLetterDiv h1, .projectDiv h1{
  text-align: center;
  font-size: 40px;
  color: var(--customDarkBlue);
  font-weight: bold;
  margin-bottom: 50px;
}

.process{
  display: flex;
  justify-content: center;
  gap: 70px;
  align-items: center;
  margin-bottom: 30px;
  
}

.process:last-child{
  margin-bottom: 0;
}

.processName{
  border: 1px solid gray;
  border-radius:  10px;
  height: 80px;
  max-width: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  /* font-weight: bold;  */
  position: relative;
  box-shadow: 0 0 10px rgb(239,60,15, 0.3);
  padding: 10px 10px 10px 35px;
  text-align: center;
}

.processName::before{
  counter-increment: processCount;
  content: counter(processCount);
  /* padding: 5px; */
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px dashed red;
  border-radius: 10px;
  font-size: 30px;
  position: absolute;
  left: 0;
  transform: translateX(-50%);
  background-color: rgb(255,251,248);
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
}

.processName::after{
  content: "";
  width: 70px;
  height: 3px;
  background-color: rgb(0,165,236);
  position: absolute;
  right: 0;
  transform: translateX(100%);
}

.processDescription{
  box-shadow: 0 0 10px #d4e6eb;
  max-width: 700px;
  padding: 10px;
  min-height: 100px ;
  border-radius: 10px;
  display: flex;
  align-items: center;
  /* font-size: 20px; */
  color: gray;
  font-weight: 500;
  background: linear-gradient(101deg,#fff,#f3fcff);
  text-align: justify;
}


/* --------------internship and course---------- */
.InternshipLetterDiv{
  padding: 50px;
  background-color: rgb(237,246,247);
  margin-bottom: 50px;
}

.InternshipLetterDiv .contentAndCertificate p{
  text-align: justify;
}
/* .InternshipLetterDiv .contentAndCertificate h5{
  text-align: center;
} */
.InternshipLetterDiv .contentAndCertificate img{
  max-width: min(90%, 450px);
  max-height: 250px;
}


/* --------------projects-------------- */
.projectDiv {
  padding: 50px;
}

.projectDiv .projectHeading{
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(19,193,145);
  padding: 5px 10px;
  border-radius: 20px;
}

.projectDiv h5, .projectDiv p{
  text-align: center;
}

.projectDiv .projects{
  margin-top: 50px;
}

.projectDiv .singleProject{
  background-color: rgb(242,251,247);
  border-radius: 20px;
  margin: 10px 0 20px;
  padding: 10px;
  padding-top: 40px;
}

.projectDiv .singleProject img{
  width: 100px;
  height: 100px;
  margin-bottom: 30px;
  border-radius: 50%;
}
.projectDiv .technologies p{
  font-weight: bolder;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 50px;
  max-width: min(90%, 200px);
}





@media (max-width: 1150px){
  .frameImg img{
    width: 400px;
  }
  .advanceFeature{
    flex-direction: column;
  }
}

@media (max-width:1150px){
  .process{
    flex-direction: column;
    gap: 40px;
  }
  .processName::after{
    width: 3px;
    height: 40px;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }

}

@media (max-width: 850px){
  .homeDiv {
    flex-direction: column-reverse;
    justify-content: space-evenly;
    padding: 0 20px;
  }
  .advanceFeature .ImgDiv img{
    max-width: 400px;
  }
  .advanceFeature .Points{
    flex-direction: column;
    align-items: center;
  }
  .advanceFeature{
    margin: 50px 20px;
  }
  .testimonial{
    margin: 100px 20px 50px;
  }
  .howToApply{
    margin: 0 20px 50px;
  }
  .InternshipLetterDiv{
    padding: 50px 20px;
  }
  .InternshipLetterDiv h1{
    margin-bottom: 20px;
  }
  .projectDiv {
    padding: 20px;
  }
}

@media (max-width: 425px){
  .frameImg img{
    width: 300px;
  }

  .frameContent h1{
    font-size: 30px;
  }

  .frameContent h5{
    font-size: 15px;
  }

  .advanceFeature .ImgDiv img{
    max-width: 300px;
  }

  .advanceFeature .heading h5{
    font-size: 15px;
  }
  .advanceFeature .heading h1, .howToApply h1, .whyUs h1, .InternshipLetterDiv h1{
    font-size: 25px;
  }

  .processContainer{
    padding: 30px 20px;
  }
  .process{
    margin-bottom: 40px;
  }
  .processName{
    font-size: 18px;
    padding-left: 25px;
    width: auto;
  }
  .processName::before{
    left: 50%;
    top: 0;
    transform: translate(-50%,-50%);
  }
}