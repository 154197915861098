@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,900;1,300;1,700&family=Rubik:wght@300;400;500;600&family=Ubuntu:ital,wght@0,400;0,500;0,700;1,500&display=swap');

/* font-family: 'Roboto', sans-serif;
font-family: 'Rubik', sans-serif;
font-family: 'Ubuntu', sans-serif; */

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Ubuntu', sans-serif;
}


:root{
  --customDarkBlue : rgb(12,46,96);
}

html {
  scrollbar-width: thin;          /* "auto" or "thin" */
  scrollbar-color: blue orange;   /* scroll thumb and track */ 
}
@media (min-width: 1366px){
  body{
    max-width: 1366px;
    margin: 0 auto;
  }
}
