footer{
  background-color: white;
  color:black;
  padding: 50px;
  margin-bottom: 50px;
}

.footerContent{
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.footerLogo{
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(39, 79, 88);
  margin-top: -50px;
  margin-left: -50px;
}

.footerLogo h3{
  margin-top: -20px;
  margin-left: 20px;
}
.footerLogo img{
  width: 50%;
  height: 80%;
  margin-bottom: 10px;
}

.footerAddress i{
  margin-right: 20px;
  color:rgb(27,214,252);
  font-size: 20px;
}

.footerConnect{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerConnect i{
  font-size: 30px;
  margin-right: 20px;
  color: rgb(27,214,252);
  cursor: pointer;
  transition: all 0.5s ease-out;
}

.footerContent i:last-child{
  margin-right: 0;
}

.footerContent i:hover{
  color: rgb(242,98,11);
  transform: rotate(360deg);
}

footer .copyWrite{
  text-align: center;
}

@media (max-width:800px){
  .footerContent{
    flex-direction: column;
    gap: 20px;
  }

  .footerAddress{
    text-align: center;
  }

}



/* ----------------social----------- */


.SocialLinks ul {
  position:fixed;
  bottom: 20px;
  right:20px;
}

ul li {
  list-style: none;
  margin-top: 10px;
}

ul li a {
  width:40px;
  height:40px;
  border-radius: 50%;
  text-decoration:none;
  box-shadow: 0 5px 5px rgb(0,0,0);
  background-color: rgb(27,214,252);;
  transition: .5s;
  display:flex;
  justify-content: center;
  align-items: center;
}

ul li a:hover {
  box-shadow: 0 2px 5px rgba(0,0,0,0.3);
}

ul li a i {
  font-size:25px;
  color: #262626;
  transition: .5s;
}

ul li:nth-child(1) a:hover i {
  color: red
}

ul li:nth-child(2) a:hover i {
  color: rgb(64,216,121);
}

ul li:nth-child(3) a:hover i {
  color: #24518b;
}
