/* 
.button {
    margin-top: 40px;
  }
  
  .advanceFeature{
    display: flex;
    margin: 50px;
    gap: 50px;
  }
  
  .advanceFeature .heading h5{
    color: gray;
  }
  
  .advanceFeature .heading .headSpan{
    display: inline-block;
    width: 60px;
    border: 1px solid rgb(255,102,59);
    margin-right: 10px;
  }
  
  .advanceFeature .heading h1{
    color: var(--customDarkBlue);
    font-weight: bolder;
    margin: 30px 0;
  }
  
  .advanceFeature .heading p{
    color: gray;
    text-align: justify;
  }
  
  .advanceFeature .ImgDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important;
    border-top-left-radius:40% ;
    border-top-right-radius: 60%;
    border-bottom-left-radius: 60%;
    border-bottom-right-radius: 40%;
  
  }
  
  .advanceFeature .ImgDiv img{
    width: 100%;
    max-width: 500px;
  }
  
  .advanceFeature .Points{
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .advanceFeature .Points .subPoint{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 300px;
  }
  .advanceFeature .Points .subPoint .test img{
      width: 100%;
      height:250px;
      border-radius: 50%;
      
  }
  
  .clients{
    display: flex;
  }
  .advanceFeature .Points .subPoint .logo{
    margin-bottom: 30px;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    color: white;
    /* background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important; */
    /* display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px; */
  /* } */
/*   
  .advanceFeature .Points .subPoint h3{
    color: var(--customDarkBlue);
    margin-bottom: 20px;
  }
  
  .advanceFeature .Points .subPoint p{
    color: gray;
    text-align: center;
  } */
  
  


   /* --------------projects-------------- */
.projectDivs {
  padding: 50px;
}

.projectDivs .projectHeading{
  position: absolute;
  font-size: 18px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(105, 209, 213);
  padding: 5px 10px;
  border-radius: 20px;
}

.projectDivs h5, .projectDivs p{
  text-align: center;
}

.projectDivs .projects{
  margin-top: 50px;
}

.projectDivs .singleProject{
  background-color: rgb(242,251,247);
  border-radius: 20px;
  margin: 10px 0 20px;
  padding: 10px;
  padding-top: 50px;
}

.projectDivs .singleProject img{
  width: 80%;
  height: 300px;
  margin-bottom: 30px;
  border-radius: 50%;
}
.projectDivs .technologies p{
  font-weight: bolder;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 50px;
  max-width: min(90%, 200px);
}