.coursesBanner img{
  width: 100%;
  max-height: 600px;
}

.courses{
  margin: 50px;
}

.courses .heading{
  display: flex;
  align-items: center;
  flex-direction: column;
  color: gray;
  margin-bottom: 50px;
}

.courses .heading h1{
  font-weight: bolder;
  color: var(--customDarkBlue);
  margin: 20px 0;
}

.courses .heading .spanDiv{
  display: inline-block;
  width: 100px;
  border: 1px solid rgb(255,102,59);
}
.coursesDiv{
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.individualCourse{
  width: 350px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  padding: 10px;
  text-align: center;
  color: gray;
  border-radius: 10px;
}

.individualCourse img{
  width: 100%;
}

.individualCourse h4{
  color: var(--customDarkBlue);
  margin: 15px 0;
}


@media (max-width:1200px){
  .individualCourse{
    width: 300px;
  }
}

@media (max-width:850px){
  .courses{
    margin: 50px 20px;
  }
}


.whyUs{
  text-align: center;
  font-size: 18px;
}

.whyUs h1{
  font-size: 40px;
  color: var(--customDarkBlue);
  font-weight: bold;
}

.whyUs h5{
  color: gray;
  margin: 0 20px;
}

.whyUsContain{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 20px;
}

.whyUs .singleContain{
  width: 300px;
  min-width: 300px;
  padding: 20px;
  transition: all 0.6s ease 0s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--customDarkBlue);
  cursor: pointer;
  margin: 15px;
}
.whyUs .singleContain .logoDiv{
  margin-bottom: 30px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(240, 244, 246);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  transition: all 0.6s ease 0s;
}

/* .whyUs .singleContain p{
  color: gray;
  text-align: center;
  margin-top: 20px;
} */


/* online courses css */

h1{
  text-align: center;
  font-weight: 20px;
}
.coursesCard {
  padding: 50px 0;
}
.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
}
.coursesCard .img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1eb2a6;
  padding: 15px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard .rate {
  margin: 20px 0;
  color: #1eb2a6;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: #1eb2a6;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #1eb2a6;
}
/*-------------online---------*/
.online {
  text-align: center;
}
.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 10px 10px;
  transition: 0.5s;
}
.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #1eb2a6;
  border-radius: 5px;
}
.online .box .show {
  opacity: 0;
}
.online .box:hover {
  background-color: #1eb2a6;
  border-radius: 5px;
  cursor: pointer;
}
.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.online .box:hover h1 {
  color: #fff;
}
/*-------------online---------*/
@media screen and (max-width: 768px) {
}


.button {
  margin-top: 40px;
}

.advanceFeature{
  display: flex;
  margin: 50px;
  gap: 50px;
}

.advanceFeature .heading h5{
  color: gray;
}

.advanceFeature .heading .headSpan{
  display: inline-block;
  width: 60px;
  border: 1px solid rgb(255,102,59);
  margin-right: 10px;
}

.advanceFeature .heading h1{
  color: var(--customDarkBlue);
  font-weight: bolder;
  margin: 30px 0;
}

.advanceFeature .heading p{
  color: gray;
  text-align: justify;
}

.advanceFeature .ImgDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important;
  border-top-left-radius:40% ;
  border-top-right-radius: 60%;
  border-bottom-left-radius: 60%;
  border-bottom-right-radius: 40%;

}

.advanceFeature .ImgDiv img{
  width: 100%;
  max-width: 500px;
}

.advanceFeature .Points{
  display: flex;
  justify-content: center;
  gap: 30px;
}

.advanceFeature .Points .subPoint{
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 300px;
}
.advanceFeature .Points .subPoint .image img{
    width: 300px;
    
}

.clients{
  display: flex;
}
.advanceFeature .Points .subPoint .logo{
  margin-bottom: 30px;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  color: white;
  /* background: linear-gradient(to right, rgb(239,60,15), rgb(249,177,1)) !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
}

.advanceFeature .Points .subPoint h3{
  color: var(--customDarkBlue);
  margin-bottom: 20px;
}

.advanceFeature .Points .subPoint p{
  color: gray;
  text-align: center;
}



/* new css */

.projectCart {
  padding: 50px;
}

.projectCart .projectHeading{
  position: absolute;
  font-size: 18px;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(235, 197, 227);
  padding: 5px 10px;
  border-radius: 20px;
}

.projectCart h5, .projectCart p{
  text-align: center;
}

.projectCart .projects{
  margin-top: 50px;
}

.projectCart .singleProject{
  background-color: rgb(239, 229, 240);
  border-radius: 20px;
  margin: 10px 0 20px;
  padding: 10px;
  padding-top: 50px;
}

.projectCart .singleProject img{
  width: 80%;
  height: 300px;
  margin-bottom: 30px;
  border-radius: 0%;
}
.projectCart .technologies p{
  font-weight: bolder;
  border: 1px solid black;
  padding: 5px 10px;
  border-radius: 50px;
  max-width: min(90%, 200px);
}
